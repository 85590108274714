















































































































import { Component, Watch } from "vue-property-decorator";

import AppVue from "@/AppVue.vue";
import { UserLookups, UserTrainingModel } from "@/core/webapi";
import { ApiService, NotificationProvider } from "@/core/services";
import { AcademicYearSelectCpt } from "@/core/components/common";

@Component({
  components: {
    AcademicYearSelectCpt,
  },
})
export default class UserProfileView extends AppVue {
  lookups = new UserLookups({
    academicYears: [],
  });
  filter: any = {};
  internalLoading = false;
  showDialog = false;
  selectedTrainingSessionId = 0;

  model = new UserTrainingModel({
    latestTrainingSessionId: null,
    latestTrainingSessionTimeBlock: "",
    availableSessions: [],
    trainingRecords: [],
  });

  @Watch("currentAcademicYear")
  async onAcademicYearChanged() {
    await this.initializeModel();
  }

  async created() {
    await this.loadLookups();
    await this.initializeModel();
  }

  async loadLookups() {
    this.isLoading = true;
    try {
      const response = await ApiService.user().getUserLookups(false, false);
      this.lookups = response.data;
    } finally {
      this.isLoading = false;
    }
  }

  changeSessionSlot() {
    this.showDialog = true;
  }

  async confirmChangeSessionSlot() {
    this.showDialog = false;
    await ApiService.user().changeTrainingSessionSlot(this.selectedTrainingSessionId);
    NotificationProvider.success("Slot successfully changed");
    await this.initializeModel();
  }

  private async initializeModel() {
    const response = await ApiService.user().getUserTrainingSessions(this.currentUserId, this.currentAcademicYear);
    this.model = response.data;
    this.selectedTrainingSessionId = this.model.latestTrainingSessionId;
  }
}
