






































import { Component } from "vue-property-decorator";

import AppVue from "@/AppVue.vue";
import { UserModel } from "@/core/webapi";
import { ApiService, NotificationProvider } from "@/core/services";
import { InputFieldCpt } from "@/core/components/common";

@Component({
  components: {
    InputFieldCpt,
  },
})
export default class UserProfileView extends AppVue {
  internalLoading = false;

  model = new UserModel({
    userId: 0,
    volunteerNumber: null,
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    modelState: {},
  });

  async created() {
    const response = await ApiService.user().getProfile();
    this.model = response.data;
  }

  private async save() {
    // this.internalLoading = true;

    this.model.modelState = {};
    const isSuccess = await ApiService.user()
      .updateProfile(this.model)
      .then(
        () => true,
        error => {
          if (error.response && error.response.data) {
            this.model.modelState = error.response.data.modelState;
          }
          this.$forceUpdate();
          return false;
        },
      );
    this.$forceUpdate();

    NotificationProvider.success("Profile updated!");

    // this.internalLoading = false;
    return isSuccess;
  }
}
