
















































































import { Component } from "vue-property-decorator";

import AppVue from "@/AppVue.vue";
import { UserLookups, VolunteerWithdrawModel } from "@/core/webapi";
import { ApiService, NotificationProvider } from "@/core/services";
import { InputTextareaCpt, AcademicYearSelectCpt } from "@/core/components/common";
import moment from "moment";

@Component({
  components: {
    InputTextareaCpt,
    AcademicYearSelectCpt,
  },
})
export default class UserWithdrawView extends AppVue {
  endOfAcademicYearMonth = 6;
  internalLoading = false;
  showDialog = false;
  lookups = new UserLookups({
    academicYears: [],
  });
  filter: any = {};
  model = new VolunteerWithdrawModel({
    reason: "",
    modelState: {},
  });

  get currentAcademicYearName() {
    return this.currentAcademicYearObject?.text;
  }

  get currentAcademicYearObject() {
    return this.lookups.academicYears.find(x => x.id === this.currentAcademicYear);
  }

  get isPastAcademicYear() {
    const today = moment();
    const currentYear = today.year();
    const currentMonth = today.month();
    return (
      this.currentAcademicYearObject &&
      (this.currentAcademicYearObject.endYear <= currentYear ||
        (this.currentAcademicYearObject.endYear === currentYear + 1 && currentMonth > this.endOfAcademicYearMonth - 1))
    );
  }

  get endOfAcademicYearMonthName() {
    return moment()
      .month(this.endOfAcademicYearMonth - 1)
      .format("MMMM");
  }

  get monthsToEnd() {
    if (!this.currentAcademicYearObject) {
      return "";
    }

    const today = moment();
    const endAcademicYear = moment(
      `${this.currentAcademicYearObject.endYear}-${this.endOfAcademicYearMonth.toString().padStart(2, "0")}-01`,
      "YYYY-MM-DD",
    );

    // if (today >= endAcademicYear) that means the diff will be negative
    if (today >= endAcademicYear) {
      return "";
    }

    const months = endAcademicYear.diff(today, "months");

    if (months === 0) {
      return "next month";
    }

    if (months === 1) {
      return `in ${months} month`;
    }

    return `in ${months} months`;
  }

  async created() {
    await this.loadLookups();
  }

  async loadLookups() {
    this.isLoading = true;
    try {
      const response = await ApiService.user().getUserLookups(false, false);
      this.lookups = response.data;
    } finally {
      this.isLoading = false;
    }
  }

  withdraw() {
    if (!this.model.reason) {
      this.model.modelState = { reason: ["The Reason field is required."] } as any;
      return;
    }
    this.showDialog = true;
  }

  async confirmWithdrawal() {
    this.isLoading = true;
    try {
      this.showDialog = false;
      this.model.modelState = {};
      this.model.academicYearId = this.currentAcademicYear;

      const isSuccess = await ApiService.user()
        .withdraw(this.model)
        .then(
          () => true,
          error => {
            this.isLoading = false;
            if (error.response && error.response.data) {
              this.model.modelState = error.response.data.modelState;
            }
            this.$forceUpdate();
            return false;
          },
        );

      if (isSuccess) {
        NotificationProvider.success("Successfully withdrawn");
        this.$eventHub.$emit("APPLICATION-WITHDRAWN");
        // If user is not in any past academic year log him out
        const response = await ApiService.user().getUserLookups(true, false);
        if (response.data.academicYears.length <= 0) {
          await this.$msal.logout();
        } else {
          this.$router.push("/user/activity");
        }
      }
    } finally {
      this.isLoading = false;
    }
  }
}
