













import { Component } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";
import { AuditRecordTableCpt, LastActivityHoursCpt } from "@/modules/audit/components";
import { AcademicYearSelectCpt } from "@/core/components/common";
import { UserLookups } from "@/core/webapi";
import { ApiService } from "@/core/services";

@Component({
  components: {
    AuditRecordTableCpt,
    LastActivityHoursCpt,
    AcademicYearSelectCpt,
  },
})
export default class UserActivityView extends AppVue {
  lookups = new UserLookups({
    academicYears: [],
  });
  filter: any = {};

  async created() {
    await this.loadLookups();
  }

  async loadLookups() {
    this.isLoading = true;
    try {
      const response = await ApiService.user().getUserLookups(true, true);
      this.lookups = response.data;
    } finally {
      this.isLoading = false;
    }
  }
}
